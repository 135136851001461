const ITEM_KEY = 'WindowSettings';
const SETTINGS_PARAMS = ['hide_nav'];

// This code uses window.sessionStorage which is basically a place to store
// data on the browser tab. This allows us to do things like store settings
// from an LTI launch that will only affect that tab/iframe without affecting
// any other tabs they may have open at the same time.
//
// We initially import the settings from the query string but then they will
// persist for as long as the tab is open.
const WindowSettings = {
  // True hide_nav=true, otherwise false
  hideNav: function() {
    return this.data()['hide_nav'] == 'true';
  },

  // All the stored settings as an object
  data: function () {
    try {
      const item = window.sessionStorage.getItem(ITEM_KEY);

      return item ? JSON.parse(item) : {};
    } catch {
      return {};
    }
  },

  // Takes relevant params from the query string and stores them in
  // sessionStorage.
  importParams: function () {
    const params = this.params();

    if (Object.keys(params).length === 0) return;

    try {
      window.sessionStorage.setItem(ITEM_KEY, JSON.stringify(params));
    } catch(err) {
      console.error('An error occurred updating sessionStorage', err);
    }
  },

  // All the relevant params from the query string as an object
  params: function() {
    const query = new URLSearchParams(window.location.search);

    return Array.from(query).filter(([key,]) =>
      SETTINGS_PARAMS.includes(key)
    ).reduce(
      (obj, [key, val]) => ({ ...obj, [key]: val }),
      {}
    );
  },

  // Add appropriate CSS classes to the <html> tag dependent on the stored
  // settings.
  addClasses: function () {
    if (!this.hideNav()) return;

    const htmlTag = document.getElementsByTagName('html')[0];
    if (htmlTag) { htmlTag.classList.add('hide-nav') }
  }
}

export { WindowSettings };
export function WindowSettingsSetup() {
  WindowSettings.importParams();
  WindowSettings.addClasses();
}
