// Main entrypoint to initialize and render the nav app

// For now, this only render profile sub-navigation dropdown and 'More' dropdown . But it can be
// expanded to handle all of the main nav bar once more pieces move to React.

import ready from 'document-ready-promise'

import { MoreItem, ProfileSubNav } from '../mc-app/nav'
import { renderComponent } from '../mc-ui/utils/renderComponent'
import { WithAppProvider as TeacherNavHeader } from '../mc-app/components/NavHeader/TeacherNavHeader'
import { WithAppProvider as ParentsNavHeader } from '../mc-app/components/NavHeader/ParentsNavHeader'
import { WithAppProvider as StudentNavHeader } from '../mc-app/components/NavHeader/StudentNavHeader'
import { WindowSettings } from '../setup/window_settings'

ready()
  .then(() => {
    /**
     * Remove the ProfileSubNav and MoreItem component together with instui_navigation feature flag
     * https://instructure.atlassian.net/browse/MCE-19641
     */
    // NOTE: These components are currently only for Teachers, however, this pack is also loaded
    // for Students and Parents. I'm not sure how the Student and Teacher nav will go in the
    // end. We may want to have one component to handle all three, or three separate components.
    // But for now, checking that the element exists first should be sufficient.
    if (!WindowSettings.hideNav()) {
      renderComponent(ProfileSubNav, 'ProfileSubNav')
      renderComponent(MoreItem, 'more-sub-nav')

      renderComponent(TeacherNavHeader, 'top-navigation-header-root')
      renderComponent(ParentsNavHeader, 'parents-top-navigation-header-root')
      renderComponent(StudentNavHeader, 'students-top-navigation-header-root')
    }
  })
  .catch((err) => {
    console.error('Unable to render the navigation bar:', err)
  })
