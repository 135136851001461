import { useState, useEffect } from 'react'

import { Badge } from '@instructure/ui-badge'
import { CondensedButton } from '@instructure/ui-buttons'
import { Flex } from '@instructure/ui-flex'
import { Menu } from '@instructure/ui-menu'
import { Spinner } from '@instructure/ui-spinner'
import { Text } from '@instructure/ui-text'
import { TruncateText } from '@instructure/ui-truncate-text'
import { View } from '@instructure/ui-view'
import {
  IconArrowOpenDownSolid,
  IconAssignmentLine,
  IconExportLine,
  IconInboxLine,
  IconQuestionLine,
  IconSettingsLine,
  IconUserLine
} from '@instructure/ui-icons'
import { IconClassroomLine } from 'mc-icons/svg'

import { camelizeKeys } from '../../mc-sdk/case-utils'

const MIN_WIDTH = '15em'
const MIN_HEIGHT = '264px' // Should match the height of the menu if everything that can be hidden is hidden
const SHOW_SPINNER_AFTER = 150 // milliseconds

// Delays showing the spinner a bit to avoid a quick, disorienting flash of the spinner,
// since content usually loads in pretty quick.
const DelayedSpinner = () => {
  const [show, setShow] = useState(false)

  useEffect(
    () => {
      let timer = setTimeout(() => setShow(true), SHOW_SPINNER_AFTER)
      return () => clearTimeout(timer)
    },
    []
  )

  if(!show) return null

  return (
    <Spinner renderTitle={() => 'Loading'} />
  )
}

const NavRow = ({ name, icon, badge }) => {
  return (
    <Flex>
      {icon && <Flex.Item padding="none x-small none none">{icon}</Flex.Item>}
      <Flex.Item>
        <Text weight={badge > 0 ? 'bold' : 'normal'}>{name}</Text>
        {badge > 0 &&
          <Badge standalone count={badge} margin="0 0 0 x-small" themeOverride={{ borderRadius: '0.375rem' }} />
        }
      </Flex.Item>
    </Flex>
  )
}

const CsrfInput = () => <input type="hidden" name="authenticity_token" value={window.CSRF_TOKEN} />

export const ProfileSubNav = ({ firstName }) => {
  const [loaded, setLoaded] = useState(false)
  const [currentTeacherId, setCurrentTeacherId] = useState(null)
  const [allTeachers, setAllTeachers] = useState([])
  const [links, setLinks] = useState(null)

  const currentTeacher = allTeachers.find(teach => teach.id == currentTeacherId) || {}
  const { id, numOfNewThreads } = camelizeKeys(currentTeacher)
  const loginableTeachers = allTeachers.filter(teach => teach.loginable)
  const parent = allTeachers.find(teach => teach.parent.loginable)
  const showSwitcher = (loginableTeachers.length > 1 || parent)

  const loadCurrentTeacher = () => {
    fetch('/api/internal/current_teacher', {
      credentials: 'same-origin'
    }).then(
      response => response.json()
    ).then(({ id, links, all_teachers }) => {
      setCurrentTeacherId(id)
      setLinks(links)
      setAllTeachers(all_teachers)
      setLoaded(true)
    }).catch(error => {
      console.error(error)
    })
  }

  const affiliationSwitcher = () => {
    if(!showSwitcher) return []

    return [
      <Menu.Group label="Account" selected={[currentTeacherId]} key="switcher_group">
        {loginableTeachers.map((teacher) =>
          <Menu.Item
            as="form" action={`/current_user/sessions/${teacher.id}`} method="post" role="button"
            value={teacher.id}
            onClick={({ currentTarget }) => currentTarget.submit()}
            key={teacher.id}
            data-qa="switch_to_teacher_account"
          >
            <input type="hidden" name="_method" value="PATCH" />
            <CsrfInput />
            {teacher.school.name}
          </Menu.Item>
        )}
        {parent &&
          <Menu.Item href="/switch_roles" data-qa="switch_to_parent_account">
            Parent Account
          </Menu.Item>
        }
      </Menu.Group>,

      <Menu.Separator key="switcher_separator" />
    ]
  }

  const currentAffiliationLinks = () => {
    // TODO: minWidth on Menu does not work in inst-ui 7, but it has been fixed in inst-ui 8.
    // So we should use that instead of this `<View minWidth={}>` hack when we are able.
    return [
      <Menu.Item href={`/teachers/${id}`} data-qa="my_profile" key="my_profile">
        <View as={"div"} minWidth={MIN_WIDTH}>
          <NavRow name="My Profile" icon={<IconUserLine />} />
        </View>
      </Menu.Item>,

      <Menu.Item href="/classrooms" data-qa="my_trackers" key="my_trackers">
        <NavRow name="My Trackers" icon={<IconClassroomLine />} />
      </Menu.Item>,

      <Menu.Item href={`/materials?filters[created_by]=${id}`} data-qa="my_assessments" key="my_assessments">
        <NavRow name="My Assessments" icon={<IconAssignmentLine />} />
      </Menu.Item>,

      <Menu.Separator key="links_separator" />,

      <Menu.Item href={links.help ?? '#'} onClick={openHelp} data-qa="help" key="help">
        <NavRow name="Help" icon={<IconQuestionLine />} />
      </Menu.Item>,

      <Menu.Item href="/message_threads" data-qa="inbox" key="inbox">
        <NavRow name="Inbox" icon={<IconInboxLine />} badge={numOfNewThreads} />
      </Menu.Item>,

      <Menu.Item href={`/teachers/${id}/edit`} data-qa="settings" key="settings">
        <NavRow name="Settings" icon={<IconSettingsLine />} />
      </Menu.Item>,

      <Menu.Item
        as="form" action="/logout" method="post" role="button"
        onClick={({ currentTarget }) => currentTarget.submit()}
        data-qa="log_out" key="log_out"
      >
        <CsrfInput />
        < NavRow name="Log Out" icon={<IconExportLine />} />
      </Menu.Item>
    ]
  }

  // Opens the help page in a new tab
  const openHelp = (e) => {
    e.preventDefault()
    window.open(links.help ?? '/help', '_blank')
  }

  return (
    <>
      <Menu
        placement="bottom end"
        onToggle={loadCurrentTeacher}
        trigger={
          <CondensedButton size="large" color="primary-inverse">
            <View as="div" maxWidth="9em" maxHeight="56px">
              <Flex as="div" padding="none none none x-small" height="56px">
                <Flex.Item shouldShrink>
                  <View as="div" textAlign="end" padding="none">
                    <TruncateText>
                      <Text size="medium" themeOverride={{ fontSizeMedium: '18px' }}>{firstName}</Text>
                    </TruncateText>
                  </View>
                </Flex.Item>
                <Flex.Item margin="x-small">
                  <IconArrowOpenDownSolid size="x-small" />
                </Flex.Item>
              </Flex>
            </View>
          </CondensedButton>
        }
      >
        { !loaded &&
          <Menu.Item disabled key="loading">
            <Flex width={MIN_WIDTH} height={MIN_HEIGHT} justifyItems="center" alignItems="center">
              <Flex.Item>
                <DelayedSpinner/>
              </Flex.Item>
            </Flex>
          </Menu.Item>
        }

        { loaded && affiliationSwitcher() }
        { loaded && currentAffiliationLinks() }
      </Menu>
    </>
  )
}

