import { BaseButton, Drilldown } from '@instructure/ui'

enum MorePages {
  Pins = 'pins',
  Community = 'community',
}

const PINS_ID = `${MorePages.Pins}-nav-item`
const COMMUNITY_ID = `${MorePages.Community}-nav-item`
const MORE_ID = `more-nav-item`

const MoreItem = ({
  pinsUrl,
  communityUrl,
}: {
  pinsUrl: string
  communityUrl: string
}) => {
  return (
    <Drilldown
      placement="bottom start"
      width={'11rem'}
      withArrow={false}
      rootPageId="root"
      trigger={
        <BaseButton
          withBorder={false}
          size="large"
          color="primary-inverse"
          withBackground={false}
          display="block"
          height="56px"
          // overrides to match the legacy nav items styling
          themeOverride={{
            borderRadius: 0,
            primaryInverseGhostHoverBackground: 'transparent',
            primaryInverseGhostActiveBoxShadow: 'none',
            largeFontSize: '1.125rem',
            largePaddingHorizontal: '1rem',
            fontFamily: 'Open Sans, sans-serif',
          }}
          data-qa={MORE_ID}
          data-impact-id={MORE_ID}
        >
          More
        </BaseButton>
      }
    >
      <Drilldown.Page id="root">
        <Drilldown.Option
          id={PINS_ID}
          key={MorePages.Pins}
          href={pinsUrl}
          data-id={PINS_ID}
          data-impact-id={PINS_ID}
        >
          Pins
        </Drilldown.Option>
        <Drilldown.Option
          id={COMMUNITY_ID}
          key={MorePages.Community}
          href={communityUrl}
          data-qa={COMMUNITY_ID}
          data-impact-id={COMMUNITY_ID}
        >
          Community
        </Drilldown.Option>
      </Drilldown.Page>
    </Drilldown>
  )
}

export { MoreItem }
